import "./IndependentStyles.css"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import HeroPages from "../../components/HeroPages";
import Footer from "../../components/Footer";

function Level2(){
    return(
        <>
            <HeroPages name="ILT - Fill In The Blanks"/>
            <div className="container">
                <h3 className="ilt-maintitle">Fill In The Blanks - Level 2</h3>
                <div className="ilt-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #1
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                            <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/1dH9-dcQl74FEZG3Nyfyt1GiUoAkg2JFX/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/1DxTc70NhLiP485TtV7mqXFnOsywr1lvy/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1LAlLXY2OXjsZ7Uq_guinhD9c01Sj6xUN/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #2
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/1ueNODlrMuXNz_7viT3cEij1HErvWdy5m/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/1SngkT_czYGOfblwVRSoDMTHOVNRcUxS3/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1TnuKSKsvqLlS_JxOV4qRRAVbV17yLL-I/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #3
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/1JNTFUjTzg2Lu3a4Rnplh-XyvnTpq__rm/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/1ahCJYBZ_jSp1IWzzihXAo1c9aZyiOBGe/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1pgfXTDNtVbTuYNcxUbC6LTNkftIYmena/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #4
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/1I7Soh84echD9dVRlJd-3GPc9X_UrOIqY/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/1PfUFRTdGsNbcKXrCfCHn-ZgYjzGraqKo/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1ZdxxoT-MDmf7hbURd432WjN8vioIzU8U/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #5
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/1WzDnWtvRmiAchMyCPchbV-IcBF3so_HB/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/14wDd9vP5v95Uj7Z1KcHLllmBM8e6Kf1w/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1rJjH6Tz0W1xLDbEeYbwoQ_aHlepwYfRK/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #6
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/1V1KqZm6smqNSeSC7spLBh-2cVQIAzeWt/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/13U23hMLAHCSVc2vqvsq99oMtcZ-qOT2V/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1g_U1cti63m6BM2KsdpJECGUVB_qN8yO1/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #7
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/1aQV7RNusur01xhgj87MBNwIBTHU5gVno/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/1jpXwyEevKfFsVDuzmp8M9qvuucGnN3wQ/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1KlfqThXvDtcjAbJRvUFtVk3NmwnUOgCH/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #8
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/12kEGOO_nvPaRWrA1csNeQu0s46Hr_gDP/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/1SpFZv5xeB6f02qQMq0xR8Gp4MiyUNpkj/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1cSENYn2xk12q6knxtUmjIU6e-1kY_wJD/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #9
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/1UHXo1xhCliYMOcSH1Z2bRd2A4ohL0KNt/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/1lKsIduyhlA8a7C-P6WZMU3vgPOuWj9g6/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1en9ffRS7vd8r9ZJVjfa9aj32sCX8tARi/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading className="accordion-header">
                                <AccordionItemButton style={{color: "black"}}>
                                    Fill In The Blanks #10
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="acc-btns">
                                    <a href="https://drive.google.com/file/d/1MDmDSsWZrr0W3A47dm0xso5Cv6wgwvu4/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Activity
                                    </a>
                                    <a href="https://drive.google.com/file/d/19XuYdN67hCsUexx4_CBei8MGPv_vo7FM/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Audio
                                    </a>
                                    <a href="https://drive.google.com/file/d/1PM7gqL5wMGQaIhhN5E2tQBWOpx0mzWrJ/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="acc-btn">
                                        Answers
                                    </a>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Level2;